import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-chained-backend";
import XHR from "i18next-xhr-backend";
import set from "lodash/set";
import moment from "moment";
import numbro from "numbro";
import { initReactI18next } from "react-i18next";
import { IConfig } from "../config";
import { localeConfig } from "./i18n-locale-configs";

export interface LocaleDateOptions {
  name: string;
  overrides?: Overrides[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  numbroLanguage?: any;
  momentLocaleParent?: string;
}

interface Overrides {
  path: string;
  pathValue: string;
}

export const initLanguages = (localeConfig: LocaleDateOptions[]) => {
  localeConfig.forEach(
    ({ name, overrides, numbroLanguage, momentLocaleParent }) => {
      if (overrides) {
        overrides.forEach(({ path, pathValue }) => {
          set(numbroLanguage, path, pathValue);
        });
      }

      numbroLanguage && numbro.registerLanguage(numbroLanguage);

      momentLocaleParent &&
        moment.defineLocale(name, { parentLocale: momentLocaleParent });
    }
  );
};

const initI18nClient = async (config: IConfig) => {
  i18n.use(LanguageDetector).use(Backend).use(initReactI18next);

  if (!i18n.isInitialized) {
    await i18n.init({
      fallbackLng: "en-GB",
      saveMissing: false,
      load: "currentOnly",
      ns: [
        "global",
        "geographic",
        "dashboard",
        "prospectSearch",
        "suppressions",
        "legalForms",
        "directorPositions",
        "internationalCodes",
        "localCodesBE",
        "localCodesCA",
        "localCodesDE",
        "localCodesFR",
        "localCodesGB",
        "localCodesIT",
        "localCodesNL",
        "localCodesUS",
        "localCodesDK",
        "caExports",
      ],
      defaultNS: "global",
      lng: "en-GB",
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        wait: true,
        useSuspense: false,
      },
      backend: {
        backends: [XHR, XHR],
        backendOptions: [
          {
            loadPath: config.LOCIZE_ENABLED
              ? `${config.UPP_API_URL}/i18n?product=prospects&namespace={{ns}}&locale={{lng}}`
              : "/locales/{{ns}}/{{lng}}.translation.json",
          },
          {
            loadPath: "/locales/{{ns}}/{{lng}}.translation.json",
          },
        ],
      },
      debug: config.DEBUG_MODE,
      returnEmptyString: false,
      returnNull: false,
    });

    initLanguages(localeConfig);
  }

  i18n.on("languageChanged", (lng) => {
    moment.locale(lng);
  });
};

const locales = [
  {
    code: "en-US",
  },
  {
    code: "en-GB",
  },
  {
    code: "fr-BE",
  },
  {
    code: "nl-BE",
  },
  {
    code: "de-DE",
  },
  {
    code: "it-IT",
  },
  {
    code: "en-IE",
  },
  {
    code: "fr-CA",
  },
  {
    code: "fr-FR",
  },
  {
    code: "ja-JP",
  },
  {
    code: "nb-NO",
  },
  {
    code: "nl-NL",
  },
  {
    code: "en-CA",
  },
  {
    code: "da-DK",
  },
  {
    code: "sv-SE",
  },
  {
    code: "de-AT",
  },
];

export { initI18nClient, locales };

export default i18n;
